var site = site || {};
var rb = rb || {};

site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
var validate_password_enabled = Drupal.settings.validate_password_enabled || 0;
var hasIAM = Drupal.settings && Drupal.settings.iam_signin;

(function ($, generic) {
  Drupal.behaviors.signIn = {
    iamSignin: function () {
      generic.jsonrpc.fetch({
        method: 'vulcan.getConfig',
        params: [{ section: 'iam' }],
        async: false,
        onBoth: function (jsonRpcResponse) {
          var iamData = jsonRpcResponse.getValue();

          if (iamData && iamData.enabled && iamData.redirect_url) {
            window.location = iamData.redirect_url;
          } else {
            window.location = '/account/signin.tmpl';
          }
        }
      });
    },
    attach: function (context, settings) {
      var self = this;
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;

      if (signedIn === '0') {
        signedIn = false;
      }

      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.full_name || '';

      firstName = firstName.replace(/\+/g, ' ');
      var firstTime = persistentCookie.first_time;
      var userDetected = !!firstName;

      // Show/hide registration/sign in links on Beaty Feed page based on user detection
      if (userDetected) {
        $('.bfeed-create-account').hide();
        $('.bfeed-sign-in').show();
      } else {
        $('.bfeed-create-account').show();
        $('.bfeed-sign-in').hide();
      }

      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      var $triggerButton = $(
        '.page-utilities__account-button, .js-launch-account, .field-mobile-menu .sign-in---my-account',
        context
      );
      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', { path: '/' });

      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, { path: '/' });
      $.cookie('signInOverlayState', null);

      var isMobile = !$('body').hasClass('device-pc');
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper',
        width: '100%',
        maxWidth: '1022px',
        fixed: true
      };

      if (isMobile) {
        colorboxSettings.top = '0px';
        colorboxSettings.height = '1000px';
      }

      // User greeting and login/logout link below Account button
      var userLoginState = $('.user-login-state');

      if (userDetected && !isMobile) {
        if (signedIn) {
          $('.user-logged-in').show();
          $('.user-logged-out').hide();
        } else {
          $('.user-logged-out').show();
          $('.user-logged-in').hide();
        }
      }

      function _launchOverlay(forceReturn, returnURL) {
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (i.e., "Save to Profile" in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function () {
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);
        $('.sign-in-component__form--registration input[name=RETURN_URL]', $overlay).val(
          '/account/index.tmpl'
        );

        _initForm($overlay);

        if (validate_password_enabled) {
          site.account.initPasswordValidationTooltip();
        }

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
        }
      }

      function errorSMS(r) {
        var result_error = r.getError();
        // console.log(result_error);
      }

      function msgShow(id, msg, type) {
        if (type === 'error') {
          $('#' + id).html("<span class='sms_msg_error'>" + msg + '</span>');
        } else {
          $('#' + id).html("<span class='sms_msg_ok'>" + msg + '</span>');
        }
      }

      function msgHide(id) {
        $('#' + id).html('');
      }

      function _initForm($wrapper) {
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $registerConfirmForm = $('.sign-in-component__confirm--registration', $wrapper);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $pass = $('input[type=password]', $registerForm);
        var $passwordLabel = $('.password_wrapper .label', $registerForm);
        var $regEmailAddress = $('input[name=PC_EMAIL_ADDRESS]', $registerForm);
        var $signInEmailAddress = $('input[name=EMAIL_ADDRESS]', $signInForm);
        var $error_messages_list = $('ul.error_messages').find('li');
        var setPasswordLabel = $('.q_password').attr('aria-label');
        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        $('.sign-in-component', $signInPage).show();
        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && $('input.error, select.error', $wrapper).length) {
          if (signInOverlayState === 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_lockout\\.\\.').is(':visible')) {
          if (signInOverlayState === 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if (isSignInPage && $('#account_exists\\.\\.').is(':visible')) {
          $signInEmailAddress.val($regEmailAddress.val());
          $signInEmailAddress.trigger('focus');
          signInOverlayState = 'signin';
        }

        // Toggle the class:
        if (signInOverlayState === 'register' || signInOverlayState === null) {
          $wrapper.addClass('registration');
        } else if (signInOverlayState === 'register-confirm') {
          $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState === 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState === 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
        }

        // if user has registered before then show sign in form
        if (firstTime === 0 && signInOverlayState !== 'register-confirm') {
          $wrapper.addClass('sign-in');
        }

        if (
          (signInOverlayState === 'register' &&
            $('form[name="registration_short"] input.error').length > 0) ||
          $('form[name="registration_short"] label.error').length > 0
        ) {
          $wrapper.removeClass('sign-in');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:
        var page_id = $('body').attr('id');

        $pass.each(function () {
          if (page_id === 'signin' || page_id === 'checkout') {
            $(this).after(
              '<div class = "visible-pass-wrapper"><label for = "visible-pass" class = "label visible-pass" style = "display: none;">' +
                setPasswordLabel +
                '</label><input id = "visible-pass" class = "visible-pass form-text" type = "text" style = "display: none;" /></div>'
            );
          } else {
            $(this).after(
              '<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>'
            );
          }
          if (!isMobile) {
            Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
          }
        });
        var $visiblePass = $('.visible-pass', $wrapper);

        // Add the user's first name to the sign in confirmation screen header:
        if (firstName) {
          var $signInConfirmHeader = $(
            '.sign-in-component__confirm--sign-in .sign-in-component__header',
            $wrapper
          );

          $signInConfirmHeader.text($signInConfirmHeader.text().replace('first_name', firstName));
          $signInConfirmHeader.text($signInConfirmHeader.text().replace('full_name', firstName));
        }

        if ($('.sign-in-component__fpw-link', $wrapper).length > 0) {
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $('input#sign-in-component__EMAIL_ADDRESS', $wrapper),
            errorListNode: $('.signin-block__lost-pass-text', $wrapper),
            forgotPasswordLink: $('#forgot-password', $wrapper),
            forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
          });
        }

        // Bind events:

        $showPass.on('change', function (e) {
          var show = $(this).is(':checked');

          $passwordLabel.toggle();
          $visiblePass.add($pass).toggle();
          if (show) {
            $('.visible-pass', $wrapper).each(function () {
              if (page_id === 'signin' || page_id === 'checkout') {
                $(this)
                  .val($(this).parent().parent().find('input.q_password').val())
                  .trigger('blur');
              } else {
                $(this).val($(this).parent().prev().val()).trigger('blur');
              }
            });
          } else {
            $pass.each(function () {
              if (page_id === 'signin' || page_id === 'checkout') {
                $(this).val($(this).next().next().children().first().val()).trigger('blur');
              } else {
                $(this).val($(this).next().children().first().val()).trigger('blur');
              }
            });
          }
        });

        // Prevent the sms form from submitting on the register-confirm overlay when the mobile number is blank
        if (signInOverlayState === 'register-confirm') {
          $('.sign-in-component__confirm-options, input[type=submit]').on('click', function () {
            var mobileNumber = $('#sign-in-component__SMSPROMO_MOBILE_NUMBER');

            if ($(mobileNumber).attr('value') === '') {
              $('input[type=hidden], [name=_SECONDARY_SUBMIT], [value=sms]').remove();
            }
          });
        }

        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $error_messages_list;
          if ($error_messages_list) {
            $error_messages_list.hide();
          }
          $wrapper.toggleClass('sign-in');
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function (event) {
          event.preventDefault();
          $.colorbox.close();
          if (signInOverlayState === 'register-confirm' && signedIn) {
            $('.my-feed-drawer .drawer-formatter__trigger').trigger('mouseover');
          }
        });

        var sms_processing_msg = '처리중...';
        var sms_error_msg = '오류발생';
        var sms_sentpin_msg = '인증번호 발송완료';
        var sms_verifiedpin_msg = '인증완료';
        var sms_prefix = signedIn
          ? 'form--profile_preferences--field--'
          : 'form--registration_short--field--';
        var area_code = sms_prefix + 'MOBILE_AREA_CODE';
        var part1 = sms_prefix + 'MOBILE_PART1';
        var part2 = sms_prefix + 'MOBILE_PART2';
        var mobile_pin = sms_prefix + 'MOBILE_PIN';
        var mobile_seq = sms_prefix + 'MOBILE_SEQ';
        var mobile_number = sms_prefix + 'MOBILE_NUMBER';
        var mobile_status = sms_prefix + 'MOBILE_STATUS';
        var mobile_bypass = sms_prefix + 'MOBILE_BYPASS';
        var phone1 = sms_prefix + 'PHONE1';
        var phone1_type = sms_prefix + 'PHONE1_TYPE';
        var choose_all = sms_prefix + 'ALL_THREE_CHOOSE';
        var accept_terms_yes = sms_prefix + 'ACCEPT_TERMS--index--y';
        var accept_privacy_yes = sms_prefix + 'ACCEPT_PRIVACY--index--y';
        var accept_thirdparty_yes = sms_prefix + 'ACCEPTED_THIRD_PARTY--index--1';
        var pc_email_promotions_yes = sms_prefix + 'PC_EMAIL_PROMOTIONS--index--1';
        var sms_promotions_yes = sms_prefix + 'SMS_PROMOTIONS--index--1';
        var postal_promotions_yes = sms_prefix + 'POSTAL_PROMOTIONS--index--1';
        var pc_email_promotions_no = sms_prefix + 'PC_EMAIL_PROMOTIONS--index--0';
        var sms_promotions_no = sms_prefix + 'SMS_PROMOTIONS--index--0';
        var postal_promotions_no = sms_prefix + 'POSTAL_PROMOTIONS--index--0';
        var ipLockedMsg = rb?.error_messages?.sms_auth_ip_locked || sms_error_msg;
        var isSmsAuthReqBtnDisabled = false;

        $('#' + mobile_pin, $wrapper).prop('disabled', true);

        // buttons
        var btn_request_pin = 'sms_request_pin';
        var btn_verify_pin = 'sms_verify_pin';
        // msg
        var sms_request_msg = 'sms_request_msg';
        var sms_verify_msg = 'sms_verify_msg';

        // PIN request
        $('#' + btn_request_pin, $wrapper).on('click', function () {
          if (isSmsAuthReqBtnDisabled) {
            return false;
          }

          var m_num =
            $('#' + area_code, $wrapper).val() +
            $('#' + part1, $wrapper).val() +
            $('#' + part2, $wrapper).val();
          var m_num_display =
            $('#' + area_code, $wrapper).val() +
            '-' +
            $('#' + part1, $wrapper).val() +
            '-' +
            $('#' + part2, $wrapper).val();
          var params = {
            mobile: m_num
          };

          // reset
          $('#' + mobile_number, $wrapper).val('');
          $('#' + mobile_pin, $wrapper).val('');
          $('#' + mobile_seq, $wrapper).val('');
          $('#' + mobile_status, $wrapper).val('0');
          $('#' + phone1, $wrapper).val('');
          $('#' + phone1_type, $wrapper).val('');

          $('#' + area_code, $wrapper).prop('disabled', true);
          $('#' + part1, $wrapper).prop('disabled', true);
          $('#' + part2, $wrapper).prop('disabled', true);
          $('#' + mobile_pin, $wrapper).prop('disabled', true);

          msgShow(sms_request_msg, sms_processing_msg);
          msgHide(sms_verify_msg);

          var id = generic.jsonrpc.fetch({
            method: 'krsms.auth',
            params: [params],
            onSuccess: function (r) {
              var result_value = r.getValue();

              if (!result_value) {
                $('#' + area_code, $wrapper).prop('disabled', false);
                $('#' + part1, $wrapper).prop('disabled', false);
                $('#' + part2, $wrapper).prop('disabled', false);

                // console.log("* Invalid SMS Auth result");
                msgShow(sms_request_msg, sms_error_msg, 'error');
              } else {
                var return_code = result_value.code;
                var return_seq = result_value.resSeq;

                if (return_code === 'PIN_SENT') {
                  // sent pin with seq successfully
                  $('#' + mobile_seq, $wrapper).val(return_seq);
                  $('#' + mobile_number, $wrapper).val(m_num_display);
                  $('#' + phone1, $wrapper).val(m_num_display);

                  $('#' + mobile_pin, $wrapper).prop('disabled', false);

                  msgShow(sms_request_msg, sms_sentpin_msg);
                  // console.log("* SMS Auth PIN sent to: " + m_num + " - " + return_seq);
                  if (result_value.disableBtn) {
                    $('#' + area_code).prop('disabled', true);
                    $('#' + part1).prop('disabled', true);
                    $('#' + part2).prop('disabled', true);
                    $('#' + btn_request_pin).addClass('disabled');
                    $('#' + btn_request_pin).css('pointer-events', 'none');
                    isSmsAuthReqBtnDisabled = true;
                    setTimeout(
                      function () {
                        $('#' + btn_request_pin).removeClass('disabled');
                        $('#' + btn_request_pin).css('pointer-events', 'auto');
                        $('#' + area_code).prop('disabled', false);
                        $('#' + part1).prop('disabled', false);
                        $('#' + part2).prop('disabled', false);
                        isSmsAuthReqBtnDisabled = false;
                      },
                      result_value.enableBtnDuration ? result_value.enableBtnDuration : 60000
                    );
                  }
                } else if (return_code === 'IP_LOCKED') {
                  $('#' + area_code).prop('disabled', false);
                  $('#' + part1).prop('disabled', false);
                  $('#' + part2).prop('disabled', false);
                  msgShow(sms_request_msg, ipLockedMsg, 'error');
                } else {
                  // error from provider
                  $('#' + area_code, $wrapper).prop('disabled', false);
                  $('#' + part1, $wrapper).prop('disabled', false);
                  $('#' + part2, $wrapper).prop('disabled', false);

                  msgShow(sms_request_msg, sms_error_msg, 'error');
                }
              }
            },
            onFailure: function (r) {
              $('#' + area_code, $wrapper).prop('disabled', false);
              $('#' + part1, $wrapper).prop('disabled', false);
              $('#' + part2, $wrapper).prop('disabled', false);

              // console.log("* SMS Auth failure");
              errorSMS(r);
              msgShow(sms_request_msg, sms_error_msg, 'error');
            }
          });
        });
        // PIN verify
        $('#' + btn_verify_pin, $wrapper).on('click', function () {
          var m_num =
            $('#' + area_code, $wrapper).val() +
            $('#' + part1, $wrapper).val() +
            $('#' + part2, $wrapper).val();
          var p_num = $('#' + mobile_pin, $wrapper).val();
          var s_num = $('#' + mobile_seq, $wrapper).val();
          var params = {
            mobile: m_num,
            pin: p_num,
            res_seq: s_num
          };

          $('#' + mobile_pin, $wrapper).prop('disabled', true);

          msgShow(sms_verify_msg, sms_processing_msg);

          var id = generic.jsonrpc.fetch({
            method: 'krsms.cnfm',
            params: [params],
            onSuccess: function (r) {
              var result_value = r.getValue();

              if (!result_value) {
                $('#' + area_code, $wrapper).prop('disabled', false);
                $('#' + part1, $wrapper).prop('disabled', false);
                $('#' + part2, $wrapper).prop('disabled', false);
                $('#' + mobile_pin, $wrapper).prop('disabled', false);

                // console.log("* Invalid SMS Verify result");
                msgShow(sms_verify_msg, sms_error_msg, 'error');
              } else {
                var return_code = result_value.code;
                var return_seq = result_value.resSeq;

                $('#' + area_code, $wrapper).prop('disabled', false);
                $('#' + part1, $wrapper).prop('disabled', false);
                $('#' + part2, $wrapper).prop('disabled', false);

                if (return_code === 'PIN_CONFIRMED') {
                  // sent pin with seq successfully
                  $('#' + mobile_seq, $wrapper).val(return_seq);
                  $('#' + mobile_status, $wrapper).val('1');
                  $('#' + phone1_type, $wrapper).val('2');
                  // console.log("* SMS Verify success!");
                  msgShow(sms_verify_msg, sms_verifiedpin_msg);
                } else {
                  // error from provider
                  $('#' + mobile_pin, $wrapper).prop('disabled', false);

                  // console.log("* SMS Verify failure code: " + return_code);
                  msgShow(sms_verify_msg, sms_error_msg, 'error');
                }
              }
            },
            onFailure: function (r) {
              $('#' + area_code, $wrapper).prop('disabled', false);
              $('#' + part1, $wrapper).prop('disabled', false);
              $('#' + part2, $wrapper).prop('disabled', false);
              $('#' + mobile_pin, $wrapper).prop('disabled', false);

              // console.log("* SMS Verify failure");
              errorSMS(r);
              msgShow(sms_verify_msg, sms_error_msg, 'error');
            }
          });
        });
        // Bypass
        $('#' + mobile_bypass, $wrapper).on('click', function () {
          var mobileNumberDisplay;
          var $areaCode = $('#' + area_code, $wrapper);
          var $part1 = $('#' + part1, $wrapper);
          var $part2 = $('#' + part2, $wrapper);
          var $mobilePin = $('#' + mobile_pin, $wrapper);
          var $mobileNumber = $('#' + mobile_number, $wrapper);
          var $phone1 = $('#' + phone1, $wrapper);
          var $mobileStatus = $('#' + mobile_status, $wrapper);
          var $phone1Type = $('#' + phone1_type, $wrapper);

          if ($('#' + mobile_bypass, $wrapper).prop('checked')) {
            $areaCode.prop('disabled', true);
            $part1.prop('disabled', true);
            $part2.prop('disabled', true);
            $mobilePin.prop('disabled', true);

            if ($areaCode.val() && $part1.val() && $part2.val()) {
              mobileNumberDisplay = $areaCode.val() + '-' + $part1.val() + '-' + $part2.val();

              $mobileNumber.val(mobileNumberDisplay);
              $phone1.val(mobileNumberDisplay);
              $mobileStatus.val('1');
              $phone1Type.val('2');
            }
          } else {
            $mobileNumber.val('');
            $phone1.val('');
            $mobileStatus.val('0');
            $phone1Type.val('');

            $areaCode.prop('disabled', false);
            $part1.prop('disabled', false);
            $part2.prop('disabled', false);
            $mobilePin.prop('disabled', false);
          }
        });

        $signInForm.add($registerForm).on('submit', function () {
          /* Set the password field to what's in the visible password field if
           Show password is checked */
          var cookieVal;
          var showPass = $showPass.is(':checked');

          if (showPass) {
            $pass.each(function () {
              if (page_id === 'signin' || page_id === 'checkout') {
                $(this).val($(this).next().next().children().first().val());
              } else {
                $(this).val($(this).next().children().first().val());
              }
            });
          }

          /* Set a cookie so we remember which form was submitted so we can
           Launch the relevant confirmation overlay on the next page load */
          cookieVal = $(this).hasClass('sign-in-component__form--sign-in')
            ? 'signin-confirm'
            : 'register';

          $.cookie('signInOverlayState', cookieVal, { path: '/' });
        });

        // To hide the Mobile Bypass Option on LIVE
        var host_url = window.location.host;

        if (host_url.indexOf('esteelauder.co.kr') !== -1) {
          $('#' + mobile_bypass, $wrapper).hide();
          $('label[for=' + mobile_bypass + ']', $wrapper).hide();
        }

        $('.disagree_message_text').hide();

        function checkedPromotions() {
          $('#' + pc_email_promotions_no, $wrapper).prop('checked', true);
          $('#' + sms_promotions_no, $wrapper).prop('checked', true);
          $('#' + postal_promotions_no, $wrapper).prop('checked', true);
        }

        function unCheckedPromotions() {
          $('#' + pc_email_promotions_no, $wrapper).prop('checked', false);
          $('#' + sms_promotions_no, $wrapper).prop('checked', false);
          $('#' + postal_promotions_no, $wrapper).prop('checked', false);
        }

        function addingDisabledAttribute() {
          $('#' + pc_email_promotions_yes, $wrapper).prop('disabled', true);
          $('#' + sms_promotions_yes, $wrapper).prop('disabled', true);
          $('#' + postal_promotions_yes, $wrapper).prop('disabled', true);
        }

        function removingDisabledAttribute() {
          $('#' + pc_email_promotions_yes, $wrapper).prop('disabled', false);
          $('#' + sms_promotions_yes, $wrapper).prop('disabled', false);
          $('#' + postal_promotions_yes, $wrapper).prop('disabled', false);
        }

        $('#' + choose_all, $wrapper).on('click', function () {
          if ($(this).is(':checked')) {
            $('#' + accept_terms_yes, $wrapper).prop('checked', true);
            $('#' + accept_privacy_yes, $wrapper).prop('checked', true);
            $('#' + accept_thirdparty_yes, $wrapper).prop('checked', true);
            if ($('input[name="ACCEPTED_THIRD_PARTY"]:checked', $wrapper).val() === '1') {
              $('.disagree_message_text').hide();
              unCheckedPromotions();
              removingDisabledAttribute();
            }
          } else {
            $('#' + accept_terms_yes, $wrapper).prop('checked', false);
            $('#' + accept_privacy_yes, $wrapper).prop('checked', false);
            $('#' + accept_thirdparty_yes, $wrapper).prop('checked', false);
            if ($('input[name="ACCEPTED_THIRD_PARTY"]:checked', $wrapper).val() === '0') {
              $('.disagree_message_text').show();
              unCheckedPromotions();
              addingDisabledAttribute();
            }
          }
        });

        $('.accept_terms', $wrapper).on('click', function () {
          if (
            $('#' + accept_terms_yes, $wrapper).is(':checked') &&
            $('#' + accept_privacy_yes, $wrapper).is(':checked') &&
            $('#' + accept_thirdparty_yes, $wrapper).is(':checked')
          ) {
            $('#' + choose_all, $wrapper).prop('checked', true);
          } else {
            $('#' + choose_all, $wrapper).prop('checked', false);
          }
        });

        $('.accept_privacy', $wrapper).on('click', function () {
          if ($('input[name="ACCEPT_PRIVACY"]:checked', $wrapper).val() === 'Y') {
            if (
              $('#' + accept_terms_yes, $wrapper).is(':checked') &&
              $('#' + accept_privacy_yes, $wrapper).is(':checked') &&
              $('#' + accept_thirdparty_yes, $wrapper).is(':checked')
            ) {
              $('#' + choose_all, $wrapper).prop('checked', true);
            }
          } else {
            $('#' + choose_all, $wrapper).prop('checked', false);
          }
        });
        $('.accepted_thirdparty', $wrapper).on('click', function () {
          if ($('input[name="ACCEPTED_THIRD_PARTY"]:checked', $wrapper).val() === '1') {
            if (
              $('#' + accept_terms_yes, $wrapper).is(':checked') &&
              $('#' + accept_privacy_yes, $wrapper).is(':checked') &&
              $('#' + accept_thirdparty_yes, $wrapper).is(':checked')
            ) {
              $('#' + choose_all, $wrapper).prop('checked', true);
            }
          } else {
            $('#' + choose_all, $wrapper).prop('checked', false);
          }
        });

        $('input[name=ACCEPTED_THIRD_PARTY]', $wrapper).on('click', function () {
          if ($('input[name="ACCEPTED_THIRD_PARTY"]:checked', $wrapper).val() === '1') {
            $('.disagree_message_text').hide();
            unCheckedPromotions();
            removingDisabledAttribute();
          } else if ($('input[name="ACCEPTED_THIRD_PARTY"]:checked', $wrapper).val() === '0') {
            $('.disagree_message_text').show();
            checkedPromotions();
            addingDisabledAttribute();
          }
        });
      }
      // /End initForm()

      $triggerButton.on('click.signIn', function (event) {
        var loginUrl = window.location.pathname;
        var redirectUrl = '/account/signin.tmpl';
        event.preventDefault();

        var forceReturn = $(this).hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $(this).attr('data-return-url') : null;

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        }
        // If the form is already on the page, focus on the first element in it
        else if ($('.sign-in-component', context).length) {
          $('.sign-in-component', context).find('.form-text:visible').first().trigger('focus');
        } else {
          if (hasIAM) {
            self.iamSignin();
          } else {
            window.location =
              loginUrl === '/' || loginUrl === redirectUrl
                ? redirectUrl
                : redirectUrl + '?return_url=' + loginUrl;
          }
          if (generic.env.isIOS4) {
            popupScroll.destroy();
            popupScroll = null;
            popupScroll = new IScroll('#colorbox', { mouseWheel: true });
            setTimeout(function () {
              popupScroll.refresh();
            }, 500);
          }
        }
      });

      // Automatically launch the overlay if the cookie is set and we're not on
      // the dedicated sign in page.
      if (!isSignInPage) {
        // Disabling the sign in confirmation functionality since it was removed from the spec.
        // if ((signInOverlayState == 'register-confirm' || signInOverlayState == 'signin-confirm') && signedIn) {
        if (signInOverlayState === 'register-confirm' && signedIn) {
          _launchOverlay(forceReturn, returnURL);
        }
      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.

      if (signedIn) {
        _initForm($('.registration-page', context));
      } else {
        _initForm($('.sign-in-component', context));
      }

      // Ensure this script doesn't break site if perlgem isn't running:
      if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined') {
        // enabling to use first_name placeholder in CMS
        var $template = $('.user-login-state');
        var rendered = Mustache.render($template.html(), { first_name: firstName });

        $template.html(rendered);
        // if user clicks on 'Sign out' link reset recognized user related cookies
        $('.sign-out-link').each(function (event) {
          var $signOutLink = $(this);
          var returnURL = window.location.pathname;
          var signOutURL = $signOutLink.attr('href') + '&success_url=' + returnURL;

          $signOutLink.attr('href', signOutURL).on('click', function (event) {
            var domain =
              '.' +
              window.location.hostname.replace(/^.*(esteelauder\.)/, function (m, $1) {
                return $1;
              });
            var cookieObj = JSON.parse(
              $.cookie('persistent_user_cookie', {
                path: '/',
                domain: domain
              })
            );

            cookieObj.full_name = null;
            $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), {
              path: '/',
              domain: domain
            });
            $.cookie('persistent_user_last_purchase', null, { path: '/' });
            $.cookie('expandMyFeedTray', 0, { path: '/' });
          });
        });
      }
      var queryParams = [];
      var URLParams = window.location.search.substr(1);

      URLParams = URLParams.split('&');
      for (var key in URLParams) {
        queryParams = !_.isEmpty(URLParams[key]) ? URLParams[key].split('=') : [];
        if (queryParams[0] === 'form_name' && queryParams[1] === 'registration') {
          $('.signin-overlay__toggle-form--dont-have-acount a').trigger('click.signIn');
          break;
        }
      }
    }
  };
})(jQuery, window.generic || {});
